<mat-toolbar color="primary">
  <a [routerLink]="'/vistoria'" class="material-icons">
    <mat-icon matRipple>keyboard_backspace</mat-icon>
  </a>
  <h1>Visualizar Vistoria</h1>
</mat-toolbar>
<form (ngSubmit)="atualizarVistoria()" [formGroup]="form">
  <div class="container">
    <mat-card class="mb-10">
      <div [hidden]=" step != 1">
        <div class="center-justify">
          <span [class]="'tarja tarja-big ' + getColorSituacao()">{{form.value.situacao}}</span>
        </div>
        <mat-form-field class="s-100 ms-5 mt-5" appearance="outline">
          <mat-label>Data/Hora</mat-label>
          <input matInput type="text" [disabled]="true"
            value="{{(form.value.dataHora | date: 'dd/MM/yyyy') + ' às ' + (form.value.dataHora | date: 'HH:ss')}}" />
        </mat-form-field>
        <mat-form-field class="s-100 ms-5 mt-5" appearance="outline">
          <mat-label>Nº Laudo</mat-label>
          <input matInput type="text" [disabled]="true" value="{{form.value.numeroLaudo}}" />
        </mat-form-field>
        <mat-form-field class="s-100 ms-5 mt-5" appearance="outline">
          <mat-label>Validade Laudo</mat-label>
          <input matInput type="text" [disabled]="true" value="{{form.value.validadeLaudo | date: 'dd/MM/yyyy'}}" />
        </mat-form-field>
        <app-informacoes-proprietario [visualizacao]="true" [form]="formProprietario"></app-informacoes-proprietario>
        <app-tipo-vistoria [visualizacao]="true" [control]="getControl('tipoVistoria')"></app-tipo-vistoria>
      </div>
      <div [hidden]=" step != 2">
        <app-informacoes-veiculo #informacoesVeiculo [visualizacao]="true"
          [ocultarBotao]="true"></app-informacoes-veiculo>
      </div>
    </mat-card>
    <div [hidden]=" step != 3">
      <app-vistoria-midia-view #midiaView [imagensVeiculo]="imagensVeiculo" [itensAgregados]="itensAgregados" [videos]="videos">
      </app-vistoria-midia-view>
    </div>
    <div [hidden]=" step != 4">
      <mat-toolbar class="dark">
        <h2>Itens Vistoriados</h2>
      </mat-toolbar>
      <mat-card class="mt-10 mb-10">
        <!-- <app-itens-vistoriados [visualizacao]="true" [itensVistoriados]="itensVistoriados"></app-itens-vistoriados> -->
        <!-- <app-itens-vistoriados [visualizacao]="true" [itensVistoriados]="itensVistoriados"></app-itens-vistoriados> -->
      </mat-card>
    </div>
    <div [hidden]=" step != 5">
      <mat-toolbar class="dark">
        <h2>Apontamentos e Observação</h2>
      </mat-toolbar>
      <mat-card class="mb-10 mt-10">
        <app-apontamento-vistoriador-arvore #arvoreApontamentos></app-apontamento-vistoriador-arvore>
      </mat-card>
      <mat-card class="mb-10 mt-10">
        <app-demais-informacoes-view [visualizacao]="true" [apontamentos]="apontamentos"
          [control]="getControl('observacao')">
        </app-demais-informacoes-view>
        <br>
        <app-emplacamento [visualizacao]="true" [control]="getControl('idEmplacamento')"></app-emplacamento>
      </mat-card>
    </div>
    <div [hidden]=" step != 6">
      <mat-card class="mb-10 mt-10">
        <mat-card class="linha-descricao" (click)="voltarVeiculo()">
          <mat-icon>directions_car</mat-icon> Informações do Veículo <mat-icon class='seta'>keyboard_arrow_right
          </mat-icon>
        </mat-card>
        <mat-card class="linha-descricao" (click)="voltarDadosVistoria()">
          <mat-icon>perm_identity</mat-icon> Dados Vistoria <mat-icon class='seta'>keyboard_arrow_right</mat-icon>
        </mat-card>
        <mat-card class="linha-descricao" (click)="voltarMidia()">
          <mat-icon>perm_media</mat-icon> Mídias <mat-icon class='seta'>keyboard_arrow_right</mat-icon>
        </mat-card>
        <!-- <mat-card class="linha-descricao" (click)="voltarItensVistoriados()">
          <mat-icon>view_headline</mat-icon> Itens Vistoriados <mat-icon class='seta'>keyboard_arrow_right</mat-icon>
        </mat-card> -->
        <mat-card class="linha-descricao" (click)="voltarApVistoriador()">
          <mat-icon>view_headline</mat-icon> Apontamentos Vistoriador <mat-icon class='seta'>keyboard_arrow_right
          </mat-icon>
        </mat-card>
      </mat-card>
    </div>

    <!-- <div class="alinha-botao-impressao">
      <button class='botao-impressao' mat-stroked-button (click)="printLaudo(vistoriaId); $event.stopPropagation();">
        <mat-icon>print</mat-icon>
      </button>
    </div> -->
    <div class="alinha-button-return" [hidden]=" step == 6 ">
      <input [hidden]="step == 6" class='favorite next-button-return' type="button" value='Retornar Resumo' name='resumo'
        (click)="returnAbstract()">
    </div>
    <div class="alinha-botao-impressao">
      <input class="alinha-botao-impressao" [hidden]=" step != 6" class='previous-button' type="button" value='Voltar'
        name='anterior' *ngIf="(step != 1 && step < 7)" [routerLink]="'/vistoria'">
    </div>

    <div *ngIf="showButtonInativarVistoria()" class="alinha-botao-inativar">
      <input class="button-inativar" [hidden]=" step != 6" type="button" (click)="refModalInativar.showModal()"
        value="Inativar vistoria" *ngIf="(step != 1 && step < 7)">
    </div>

    <!-- <div class="alinha-botao-atualizar">
      <input class="button-atualizar" [hidden]=" step != 6" type="submit" value="Atualizar vistoria"
        *ngIf="(step != 1 && step < 7)">
    </div> -->
  </div>
</form>

<mat-card *ngIf="justificativaInativa" class="card-mensagem-justificativa">
  <div>
    <span class="justificativa-inativar"> Justificativa inativação:</span>
    <span class="limitar-tamanho-justificativa"> {{justificativaInativa }} </span>
  </div>
</mat-card>

<dialog #refModalInativar>
  <button class="botao-close" (click)="refModalInativar.close()">X</button>
  <div class="formulario">
    <span class="titulo-popup"> Tem certeza que deseja inativar essa vistoria? </span>
    <textarea [(ngModel)]="mensagemJustificativaValue" maxlength="100" cols="30" rows="10" placeholder="Justificativa"
      class="mensagem-justificativa" onkeyup="this.value = this.value.toUpperCase();"></textarea>
    <button type="button" (click)="inativarVistoria()" class="button-send">Inativar</button>
  </div>
</dialog>
